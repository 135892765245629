import i18n from "@/plugins/i18n.js";
import CsharpEnum from "@/_helpers/CsharpEnum";

export default {
  types: {
    title: { name: i18n.t("module.meta.fontStyleTypes.title"), value: 0 },
    value: { name: i18n.t("module.meta.fontStyleTypes.value"), value: 1 },
    content: { name: i18n.t("module.meta.fontStyleTypes.content"), value: 2 },
  },

  weights: [
    { name: "Thin", value: "font-weight-thin" },
    { name: "Light", value: "font-weight-light" },
    { name: "Regular", value: "font-weight-regular" },
    { name: "Medium", value: "font-weight-medium" },
    { name: "Bold", value: "font-weight-bold" },
    { name: "Black", value: "font-weight-black" },
  ],

  sizes: [0.875, 1, 1.25, 1.5, 2.125, 3, 3.75, 6],

  moduleUsedTypes: [{ key: CsharpEnum.ModuleType.SINGLE, value: [0, 1] }],
};
